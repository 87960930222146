<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <advanced-table
        :tableColumns="tableColumns"
        dataAction="audit/GET_AUDIT"
        :dataURLParams="{ type: $route.params.type }"
        ref="devicesTable"
      >
        <template #cell(createdAt)="data">
          {{ format_date(data.item.createdAt) }}
        </template>
        <template #cell(user)="data">
          {{ data.item.user.email }}
        </template>
        <template #cell(type)="data">
          {{ data.item.type == 1 ? $t('login.login') : $t('logout') }}
        </template>

        <template #table-colgroup="scope">
          <col
            v-for="field in scope.fields"
            :key="field.key"
            :style="{ width: field.key === 'actions' ? '50px' : '400px' }"
          />
        </template>
        <!-- Column: Actions -->
      </advanced-table>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BDropdown,
  BDropdownItem,
  BCard,
  BFormCheckbox,
} from 'bootstrap-vue';
import AdvancedTable from '@/views/components/AdvancedTable.vue';
import i18n from '@/libs/i18n';

export default {
  components: {
    BButton,
    BDropdown,
    BDropdownItem,
    BCard,
    BFormCheckbox,
    AdvancedTable,
  },
  data() {
    return {
      tableColumns: [
        { key: 'createdAt', label: i18n.tc('date'), sortable: true },
        { key: 'ip', label: i18n.tc('ip'), sortable: true },
        { key: 'user', label: i18n.tc('user'), sortable: true },
        { key: 'browser', label: i18n.tc('audit.browser'), sortable: false },
        { key: 'type', label: i18n.tc('action'), sortable: true },
      ],
    };
  },
  methods: {
    format_date(value) {
      return this.$moment(value).format('DD.MM.YYYY HH:mm');
    },
  },
  watch: {
    '$route.params.type': function(id) {
      setTimeout(() => {
        this.$refs.devicesTable.refresh();
      }, 50);
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
